<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility-08">
    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-top -->
      <div class="header-top main-component">
        <div class="header-column">
          <nav class="kb-breadcrumb">
            <ol class="kb-breadcrumb-list">
              <li class="kb-breadcrumb-item"><a href="#" class="kb-breadcrumb-link"><span class="kb-breadcrumb-text">홈</span></a></li>
              <li class="kb-breadcrumb-item"><a href="#" class="kb-breadcrumb-link"><span class="kb-breadcrumb-text">생활연수</span></a></li>
              <li class="kb-breadcrumb-item is-active" aria-current="page"><span class="kb-breadcrumb-text">운영자 문의</span></li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">운영자 문의</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content">
      <div class="min-component">
        <!-- content-section:안내사항 -->
        <section class="content-section">
          <header class="section-header"><h3 class="title">안내사항</h3></header>
          <div class="segment-box">
            <p class="text">생활연수에 관한 궁금하신 점이 있으시면 운영자에게 질문을 보내주세요. <br/>질문하신 내용은 각 연수원 담당자가 확인후 알리미로 알려드립니다.</p>
          </div>
        </section>
        <!-- content-section:문의사항 입력 -->
        <section class="content-section section-divider">
          <header class="section-header"><h3 class="title">문의사항 입력</h3></header>
          <div class="kb-form-fields field-inquiry">
            <!-- kb-form-row -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">제목</span>
                </label>
              </div>
              <div class="kb-form-column">
                <input v-model="insInquiry.inqTitle" type="text" class="kb-form-control" placeholder="제목을 입력하세요." />
              </div>
            </div>
            <!-- kb-form-row -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">연수원 구분</span>
                </label>
              </div>
              <div class="kb-form-column">
                <div class="kb-form-dropdown" :class="{'is-active': toggles.trnctOpen}">
                  <button class="kb-form-dropdown-btn" @click="openOption"><span class="text">{{ toggles.label }}</span><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item" v-for="item in trnctMstList" :key="item.trnctMstSn">
                          <a href="javascript:" class="dropdown-option-link" @click="selectOption(item)">
                            <span class="dropdown-option-text">{{ item.trnctNm }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- kb-form-row -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">문의내용</span>
                </label>
              </div>
              <div class="kb-form-column">
                <!-- textarea height:auto -->
                <textarea v-model="insInquiry.inqCn" rows="5" placeholder="운영자에게 발송할 문의 내용을 작성하세요." class="kb-form-control" maxlength="300"></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ textLength }} / 300</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kb-form-fields-bottom justify-center">
            <button class="kb-btn kb-btn-primary" type="button" @click="saveTrnctInquiry"><span class="text">발송하기</span></button>
          </div>
        </section>
        <!-- //content-section:문의사항 입력 -->
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {reactive, ref, onMounted, computed } from 'vue';
import {useStore} from "vuex";
import {ACT_GET_TRNCT_MST_LIST, ACT_INSERT_INQUIRY} from "@/store/modules/trnct/trnct";
import {lengthCheck, isSuccess} from "@/assets/js/util";
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: "Inquiry",
  setup(){
    const store = useStore();
    const {showMessage} = useAlert();

    const toggles = reactive({
      trnctOpen: false,
      label : '연수원 선택'
    });

    const trnctMstList = ref([]);

    const insInquiry = reactive({
      inqTitle : null,
      inqCn: "",
      trnctMstSn: null
    });

    const getTrnctMstList = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_MST_LIST}`).then(res => {
        if (lengthCheck(res)) {
          trnctMstList.value = res.items;
        }
      }).catch((e) => {
        console.error(e);
      })
    }

    const saveTrnctInquiry = () => {

      if(insInquiry.trnctMstSn == null){
        alert('연수원을 선택해주세요');
        return;
      }
      store.dispatch(`trnct/${ACT_INSERT_INQUIRY}`, insInquiry).then(res => {
        if (isSuccess(res)) {
          showMessage('문의가 등록되었습니다.');
          insInquiry.inqTitle = null;
          insInquiry.inqCn = "";
          insInquiry.trnctMstSn = null;
          toggles.label = '연수원 선택';
        }
      }).catch((e) => {
        console.error(e);
      })
    }

    const openOption = () => {
      console.log('asdfasdf')
      if(toggles.trnctOpen === true){
        toggles.trnctOpen = false;
      } else{
        toggles.trnctOpen = true;
      }
    }

    const selectOption = (item) => {
      toggles.label = item.trnctNm;
      toggles.trnctOpen = false;
      insInquiry.trnctMstSn = item.trnctMstSn;
      console.log(item);
    }

    const textLength = computed(() => {
      return insInquiry.inqCn.length
    });

    onMounted(()=>{
      getTrnctMstList();
    });

    return {
      toggles,
      openOption,
      trnctMstList,
      getTrnctMstList,
      selectOption,
      insInquiry,
      saveTrnctInquiry,
      textLength
    }
  }
}
</script>

<style scoped>

</style>
